import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";

const CreateSubmissionForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [submission, setSubmission] = useState({
        first_name: formData.name ? formData.name.split(" ")[0] : '',
        last_name: formData.name ? formData.name.split(" ").pop() : '',
        email_address: formData?.email || (formData.emails ? formData?.emails[0]?.email : ''),
        phone_number: formData?.phone || '',
        zip: formData?.zip_code || '',
        customer_id: formData?.id || null
    })

    let [formDisable, setFormDisable] = useState(false);


    function submit(event) {
        event.preventDefault();
        secureFetch(context?.server + '/create-blank-submission', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submission: submission
            })
        })
        .then(resp => resp.json())
        .then(sub => {
            submitForm(sub);
            closeModal();
        })
    }

    return (
        <div>
            <form id='CreateSubmissionForm' onSubmit={submit} />
            <fieldset form='CreateSubmissionForm' disabled={formDisable ? 'disabled' : ''}>
                <div style={{padding: '15px 15px'}} className='bg-light'>
                    <h1 className="dark-text">Create New Submission</h1>
                    <br/>
                    <div className="container">
                        <label className="dark-text text-left contained">First Name:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.first_name} onChange={e => setSubmission({...submission, first_name: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Last Name:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.last_name} onChange={e => setSubmission({...submission, last_name: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Email:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.email_address} onChange={e => setSubmission({...submission, email_address: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Phone:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.phone_number} onChange={e => setSubmission({...submission, phone_number: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">State:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.state} onChange={e => setSubmission({...submission, state: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Country:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.country} onChange={e => setSubmission({...submission, country: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Zip:&nbsp;&nbsp;&nbsp;
                            <input disabled = {formData?.id} form='CreateSubmissionForm' type='text' value={submission?.zip} onChange={e => setSubmission({...submission, zip: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Interested In:&nbsp;&nbsp;&nbsp;
                            <input form='CreateSubmissionForm' type='text' value={submission?.interested_in} onChange={e => setSubmission({...submission, interested_in: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Reason for Selling:&nbsp;&nbsp;&nbsp;
                            <input form='CreateSubmissionForm' type='text' value={submission?.reason_for_selling} onChange={e => setSubmission({...submission, reason_for_selling: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Preferred Rep:&nbsp;&nbsp;&nbsp;
                            <input form='CreateSubmissionForm' type='text' value={submission?.preferred_rep} onChange={e => setSubmission({...submission, preferred_rep: e.target.value})} />
                        </label><br/>
                    </div>
                    <br/>
                    <label className="dark-text text-left">Additional Comments:</label>
                    <textarea form='CreateSubmissionForm' rows='8' cols='45' value={submission?.additional_comments} onChange={e => setSubmission({...submission, additional_comments: e.target.value})} />
                    <br/>
                    <br/>
                    <input form='CreateSubmissionForm' className="btn bg-dark light-text" type='submit' />
                </div>
            </fieldset>
        </div>
    )
}

export default CreateSubmissionForm;