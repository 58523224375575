import { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { secureFetch } from '../util/flip_utils';
import InfoBox from './InfoBox';
import KeyValuePair from './KeyValuePair';

const PayOutForm = ({ formData, closeModal, submitForm }) => {
    let context = useContext(AppContext);
    let [payment, setPayment] = useState(formData);
    let [payMethods, setPayMethods] = useState([]);

    let [formDisable, setFormDisable] = useState(true);

    function checkEntitled() {
        secureFetch(context.server + '/verify-entitled', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${context?.accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                entitlement: 'pay-out',
            }),
        })
            .then((resp) => resp.json())
            .then((result) => {
                if (!result?.access) {
                    alert('You are not entitled to close payments.');
                    closeModal();
                } else {
                    setFormDisable(false);
                }
            });
    }

    function fetchMethods() {
        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${context?.accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName: 'payment_methods' }),
        })
            .then((resp) => resp.json())
            .then((results) =>
                setPayMethods(results?.filter((m) => m?.active == 1)),
            );
    }

    useEffect(() => {
        checkEntitled();
        fetchMethods();
    }, []);

    function submit(event) {
        event.preventDefault();

        let datestring = new Date().toISOString().replace('T', ' ');
        datestring = datestring.substring(0, datestring.length - 5); //cut off last .0000 seconds
        secureFetch(context.server + '/pay-out', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${context?.accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                adjustment: {
                    amount: payment?.amount,
                    reason: `Payout Link: ${payment?.payout_link}\n${payment?.payout_notes}`,
                },
                payment: {
                    ...payment,
                    prev_payment_status_id: payment?.payment_status_id,
                    payment_status_id: 3,
                    new_payment_date: datestring,
                    payer_id: context?.user?.id,
                },
            }),
        }).then(() => {
            submitForm();
            closeModal();
        });
    }

    return (
        <div>
            <form id="PayOutForm" onSubmit={submit} />
            <fieldset
                form="PayOutForm"
                disabled={formDisable ? 'disabled' : ''}
            >
                <div className="bg-light" style={{ padding: '15px 15px' }}>
                    <h1 className="dark-text">Pay Out:</h1>
                    <InfoBox title="Payment Info:">
                        {/* <KeyValuePair label='Payment ID:' value={payment?.id} textClass='dark-text' /> */}
                        <KeyValuePair
                            label="Amount:"
                            value={payment?.amount}
                            textClass="dark-text"
                        />
                        <KeyValuePair
                            label="SKU:"
                            value={<p className="red-text">{payment?.sku}</p>}
                            textClass="dark-text"
                        />
                        <KeyValuePair
                            label="Title:"
                            value={payment?.title}
                            textClass="dark-text"
                        />
                        {payment?.ra_id ? (
                            <KeyValuePair
                                label="RA:"
                                value={
                                    <p className="green-text">
                                        {payment?.ra_id}
                                    </p>
                                }
                                textClass="dark-text"
                            />
                        ) : (
                            <></>
                        )}
                        {payment?.tpa_number ? (
                            <KeyValuePair
                                label="RMA:"
                                value={payment?.tpa_number}
                                textClass="dark-text"
                            />
                        ) : (
                            <></>
                        )}
                        <KeyValuePair
                            label="Payment Type:"
                            value={payment?.payment_type}
                            textClass="dark-text"
                        />
                        <KeyValuePair
                            label="Notes:"
                            value={payment?.notes}
                            textClass="dark-text"
                        />
                    </InfoBox>
                    <br />
                    <label className="dark-text text-left">
                        Payment Method:&nbsp;&nbsp;&nbsp;
                        <select
                            form="PayOutForm"
                            required
                            className="contained"
                            onChange={(e) =>
                                setPayment({
                                    ...payment,
                                    payment_method_id: e.target.value,
                                })
                            }
                            value={payment?.payment_method_id}
                        >
                            <option value={null} key={0}>
                                *** SELECT PAYMENT METHOD ***
                            </option>
                            {payMethods.map((row) => (
                                <option key={row?.id} value={row?.id}>
                                    {row?.payment_method}
                                </option>
                            ))}
                        </select>
                    </label>
                    <br />
                    <label className="dark-text text-left">
                        Payout Link:&nbsp;&nbsp;&nbsp;
                        <input
                            form="PayOutForm"
                            style={{ width: '700px' }}
                            value={payment?.payout_link}
                            onChange={(e) =>
                                setPayment({
                                    ...payment,
                                    payout_link: e.target.value,
                                })
                            }
                        />
                    </label>
                    <br />
                    <label className="dark-text text-left">Payout Notes:</label>
                    <textarea
                        form="PayOutForm"
                        style={{
                            minWidth: '800px',
                            width: '800px',
                            maxWidth: '800px',
                        }}
                        rows="6"
                        value={payment?.payout_notes}
                        onChange={(e) =>
                            setPayment({
                                ...payment,
                                payout_notes: e.target.value,
                            })
                        }
                    />
                    <br />
                    <br />
                    <input
                        form="PayOutForm"
                        type="submit"
                        className="btn light-text bg-dark"
                    />
                </div>
            </fieldset>
        </div>
    );
};

export default PayOutForm;
