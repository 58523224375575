import { useContext, useState, useEffect } from "react";
import AppContext from "../AppContext";
import CreateClassificationForm from "../components/CreateClassificationForm";
import CreateOptionSetForm from "../components/CreateOptionSetForm";
import EditClassificationForm from "../components/EditClassificationForm";
import EditOptionSetForm from "../components/EditOptionSetForm";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import { secureFetch } from "../util/flip_utils";

const FilterOptions = (props) => {
  let context = useContext(AppContext);
  let [classifications, setClassifications] = useState([]);
  let [optionSets, setOptionSets] = useState([]);

  function fetchClassifications() {
    secureFetch(context.server + "/get-all", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${context?.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sort: {
            key: "sequence_number",
            order: 1,
          },
        },
        tableName: "classifications",
      }),
    })
      .then((resp) => resp.json())
      .then((results) => setClassifications(results));
  }

  function fetchOptionSets() {
    secureFetch(context.server + "/option-sets", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${context?.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((results) => setOptionSets(results));
  }

  useEffect(() => {
    fetchClassifications();
    fetchOptionSets();
  }, []);

  return (
    <div>
      <h1 className="light-text">Manage Filter Options</h1>
      <div className="container">
        <div className="contained">
          <InfoBox title="Classifications:">
            {classifications.map((row) => (
              <div>
                <ModalContainer
                  triggerText={
                    <>
                      <b>{row?.supertype}</b> | {row?.name}
                    </>
                  }
                  buttonClass="btn"
                  formData={row}
                  submitForm={() => {
                    fetchClassifications();
                  }}
                  FormComponent={EditClassificationForm}
                />
              </div>
            ))}
          </InfoBox>
          <ModalContainer
            triggerText="Create Classification"
            formData={{}}
            FormComponent={CreateClassificationForm}
            submitForm={() => {
              fetchClassifications();
            }}
          />
        </div>
        <div className="contained">
          <InfoBox
            title={
              <>
                Option Sets:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i style={{ fontSize: "10pt" }}>(click to edit)</i>
              </>
            }
          >
            {optionSets.map((row) => (
              <KeyValuePair
                label={
                  <ModalContainer
                    triggerText={<b>{row?.name}</b>}
                    buttonClass="btn"
                    formData={row}
                    submitForm={() => {
                      fetchOptionSets();
                    }}
                    FormComponent={EditOptionSetForm}
                  />
                }
                value={row?.option_type}
                textClass="dark-text"
              />
            ))}
          </InfoBox>
          <ModalContainer
            triggerText="Create Option Set"
            formData={{}}
            FormComponent={CreateOptionSetForm}
            submitForm={() => {
              fetchOptionSets();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
