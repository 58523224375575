import NavDropdown from './NavDropdown';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import RightChevron from '../assets/svg/right-chevron.svg';
import LeftChevron from '../assets/svg/left-chevron.svg';

//navbar component, the NavLink tags act as links to their respective pages

const Navigation = (props) => {
    let [activeDropdown, setActiveDropdown] = useState(-1); //tracks which of the dropdowns in the navbar is open, so that only one is open at a time

    function showDropdown(key) {
        return () => {
            //console.log(key)
            if (activeDropdown === key)
                //if you click the selected dropdwn, close them all
                setActiveDropdown(-1);
            else setActiveDropdown(key); //otherwise open the one you clicked on
        };
    }

    function closeAll() {
        //console.log('closeAll')
        setActiveDropdown(-1);
    }

    const containerRef = useRef(null);

    //This should put these event listeners into page once on a render, and then thats it.
    useEffect(() => {
        // Function to handle clicks outside of the component
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                closeAll(); // Close the submenu when clicked outside
            }
        };

        // Attach event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Detach event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    return (
        <nav
            ref={containerRef}
            className={
                'flex-none bg-gray-400 relative w-full max-h-fit border-b border-gray-900'
            }
        >
            <ul className={'flex'}>
                <li className={'hover:bg-custom-gray '}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName="bg-custom-gray"
                        to="/"
                    >
                        <span className={'p-4'}>Home</span>
                    </NavLink>
                </li>
                <li
                    className={
                        activeDropdown === 1
                            ? 'bg-custom-gray relative'
                            : 'hover:bg-custom-gray relative'
                    }
                >
                    {
                        <NavDropdown
                            title="Catalog"
                            isShown={activeDropdown === 1}
                            key={1}
                            clickHandler={showDropdown(1)}
                            closeAll={closeAll}
                            contents={[
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/product-list"
                                >
                                    Products
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/item-list"
                                >
                                    Items
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/tpa-list"
                                >
                                    RMAs
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/brand-list"
                                >
                                    Brands
                                </NavLink>,
                                // <NavLink
                                //   exact
                                //   onClick={closeAll}
                                //   className={
                                //     "hover:bg-submenu-selection-gray text-inherit p-4 block text-left"
                                //   }
                                //   activeClassName="bg-submenu-selection-gray"
                                //   to="/order-list"
                                // >
                                //   Orders
                                // </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/draft-order-list"
                                >
                                    Draft Orders
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/customer-list"
                                >
                                    Customers
                                </NavLink>,
                                //  <NavLink exact onClick={closeAll} className={"hover:bg-submenu-selection-gray text-inherit p-4 block text-left"} activeClassName="bg-submenu-selection-gray" to='/inventory-list'>Inventory</NavLink>
                            ]}
                        />
                    }
                </li>
                <li className={'hover:bg-custom-gray'}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName=" bg-custom-gray "
                        to="/submission-list"
                    >
                        <span className={'p-4'}>Submissions</span>
                    </NavLink>
                </li>
                <li
                    className={
                        activeDropdown === 2
                            ? 'bg-custom-gray relative'
                            : 'hover:bg-custom-gray relative'
                    }
                >
                    {
                        <NavDropdown
                            title="Testing"
                            isShown={activeDropdown === 2}
                            key={2}
                            clickHandler={showDropdown(2)}
                            closeAll={closeAll}
                            contents={[
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/item-list"
                                >
                                    Search Items
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/product-list"
                                >
                                    Search Products
                                </NavLink>,
                            ]}
                        />
                    }
                </li>

                <li className={'hover:bg-custom-gray'}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName=" bg-custom-gray "
                        to="/listing-queue"
                    >
                        <span className={'p-4'}>Listing</span>
                    </NavLink>
                </li>
                {/*<li><NavLink onClick={closeAll} exact activeClassName='current' to='/stocking'>Stocking</NavLink></li>*/}
                <li
                    className={
                        activeDropdown === 3
                            ? 'bg-custom-gray relative'
                            : 'hover:bg-custom-gray relative'
                    }
                >
                    {
                        <NavDropdown
                            title="Tools"
                            isShown={activeDropdown === 3}
                            key={3}
                            clickHandler={showDropdown(3)}
                            closeAll={closeAll}
                            contents={[
                                //  <NavLink exact className={"hover:bg-submenu-selection-gray text-inherit p-4 block text-left"} activeClassName='bg-submenu-selection-gray' to='/create-tpa'>Create RMA</NavLink>,
                                //<NavLink exact className={" text-inherit p-4 block text-left"} activeClassName='bg-submenu-selection-gray' to='/create-draft-order'>Create Draft Order</NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/edit-entitlements"
                                >
                                    Entitlements
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/docs"
                                >
                                    Documentation
                                </NavLink>,
                                //<NavLink exact onClick={closeAll} className={" text-inherit p-4 block text-left"} activeClassName='bg-submenu-selection-gray' to='/file-uploader'>Upload Files</NavLink>,
                                //  <NavLink exact onClick={closeAll} className={"hover:bg-submenu-selection-gray text-inherit p-4 block text-left"} activeClassName='bg-submenu-selection-gray' to='/brand-ratings'>Brand Rating Manager</NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/filter-options"
                                >
                                    Option Set / Classification Manager
                                </NavLink>,
                                <NavLink
                                    exact
                                    onClick={closeAll}
                                    className={
                                        'hover:bg-submenu-selection-gray text-inherit p-4 block text-left'
                                    }
                                    activeClassName="bg-submenu-selection-gray"
                                    to="/product-management"
                                >
                                    Product Management
                                </NavLink>,
                            ]}
                        />
                    }
                </li>
                {/*<li><NavLink exact activeClassName='current' to='/sandbox'>Sandbox</NavLink></li>*/}
                <li className={'hover:bg-custom-gray'}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName=" bg-custom-gray "
                        to="/ra-list"
                    >
                        <span className={'p-4'}>RAs</span>
                    </NavLink>
                </li>
                <li className={'hover:bg-custom-gray'}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName=" bg-custom-gray "
                        to="/outgoing-payments"
                    >
                        <span className={'p-4'}>Accounting</span>
                    </NavLink>
                </li>

                <li className={'hover:bg-custom-gray'}>
                    <NavLink
                        onClick={closeAll}
                        className={'text-inherit block text-left p-4'}
                        exact
                        activeClassName=" bg-custom-gray "
                        to="/reports"
                    >
                        <span className={'p-4 '}>Reports</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;
